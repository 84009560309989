import { render, staticRenderFns } from "./AddressesView.vue?vue&type=template&id=68524cc9&scoped=true&"
import script from "./AddressesView.vue?vue&type=script&lang=ts&"
export * from "./AddressesView.vue?vue&type=script&lang=ts&"
import style0 from "./AddressesView.vue?vue&type=style&index=0&id=68524cc9&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68524cc9",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { BListGroup } from 'bootstrap-vue'
import { BListGroupItem } from 'bootstrap-vue'
import { BMedia } from 'bootstrap-vue'
import IconHouse from '@/assets/icons/icon-house.svg'
import IconPlus from '@/assets/icons/icon-plus.svg'
installComponents(component, {BListGroup,BListGroupItem,BMedia,IconHouse,IconPlus})
