





































import { Component, Vue } from 'vue-property-decorator'
import { mapState, mapGetters } from 'vuex'
import Address from '@/models/Address'
import AddressElement from '@/components/Address.vue'
import Card from '@/components/Card.vue'
import BackLink from '@/components/BackLink.vue'
import { PPAddressErrorType } from '@/ppapi/PPAddressError'

@Component({
  components: { AddressElement, BackLink, Card },
  computed: {
    ...mapState('addresses', { address: (state: any) => state.editor.address }),
    ...mapGetters('addresses', ['isAddressConfirmable', 'addressErrorType']),
  },
})
export default class EditAddressModalConfirmAddress extends Vue {
  readonly address!: Address
  readonly isAddressConfirmable!: boolean
  readonly addressErrorType!: PPAddressErrorType

  get headerText() {
    if (this.addressErrorType === PPAddressErrorType.stateRequiresId) {
      return this.$t('More Information Required')
    } else if (this.isAddressConfirmable) {
      return this.$t('Confirm Address')
    } else {
      return this.$t('Address not found')
    }
  }

  get instructionsText() {
    switch (this.addressErrorType) {
      case PPAddressErrorType.stateRequiresId:
        return this.$t('addresses.stateRequiresId')
      case PPAddressErrorType.needsStreet2:
        return this.$t('addresses.needsStreet2')
      case PPAddressErrorType.poBox:
        return this.$t('addresses.poBox')
      case PPAddressErrorType.notFound:
      case PPAddressErrorType.invalid:
      case PPAddressErrorType.other:
      default:
        if (this.isAddressConfirmable) {
          return this.$t('addresses.addressNotFound')
        } else {
          return this.$t('addresses.addressNotFoundNoConfirm')
        }
    }
  }

  confirmAddress() {
    this.$emit('confirmAddress', this.address, true)
  }

  back() {
    this.$emit('back')
  }

  close() {
    this.$emit('close')
  }
}
