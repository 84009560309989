



























import { Component, Vue, Prop } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import Address from '@/models/Address'
import User from '@/models/User'
import AddressElement from '@/components/Address.vue'

@Component({
  components: { AddressElement },
  computed: {
    ...mapGetters('user', ['authenticatedUser']),
    ...mapGetters('asyncStatus', ['hasSucceeded']),
  },
})
export default class AddressCard extends Vue {
  readonly hasSucceeded!: (key: string) => boolean
  readonly authenticatedUser!: User

  @Prop({ type: Address, required: true })
  address!: Address

  async deleteAddress() {
    const contentNode = this.$createElement(AddressElement, { props: { address: this.address } })
    const confirmed = await this.$bvModal.msgBoxConfirm([contentNode], {
      title: this.$t('Really delete this address?') as string,
      id: 'confirm-delete-address',
      okVariant: 'outline-danger',
      okTitle: this.$t('Delete') as string,
      cancelTitle: this.$t('Cancel') as string,
      centered: true,
    })

    if (confirmed) {
      await this.$store.dispatch('addresses/delete', { address: this.address })
      if (!this.hasSucceeded('addresses/delete')) {
        this.showError({
          title: this.$t('Error') as string,
          message: this.$t('addresses.failedToDelete') as string,
        })
      }
    }
  }

  async setDefaultAddress() {
    if (this.address.requireIdentification && !this.authenticatedUser.hasIdentification) {
      this.$store.dispatch('user/setUserIdentificationModal', {
        visible: true,
        onComplete: this.setDefaultAddress,
      })
    } else {
      await this.$store.dispatch('addresses/setCurrentShippingAddress', { address: this.address })
      if (!this.hasSucceeded('addresses/setCurrentShippingAddress')) {
        this.showError({
          title: this.$t('Error') as string,
          message: this.$t('addresses.failedToSetDefault') as string,
        })
      }
    }
  }

  editAddress() {
    this.$store.dispatch('addresses/beginEditAddress', this.address)
  }

  showError({ title, message }: { title: string; message: string }) {
    this.$bvToast.toast(message, {
      title,
      variant: 'danger',
    })
  }
}
