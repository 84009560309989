


















































import { Component, Vue } from 'vue-property-decorator'

import { mapState, mapGetters } from 'vuex'
import PageHeader from '@/components/PageHeader.vue'
import HelpSearchCard from '@/components/HelpSearchCard.vue'
import SkeletonLine from '@/components/SkeletonLine.vue'
import Address from '@/models/Address'
import EditAddressModal from '@/components/settings/addresses/EditAddressModal.vue'
import AlertMessage from '@/components/AlertMessage.vue'
import AddressCard from '@/components/settings/addresses/AddressCard.vue'
import Card from '@/components/Card.vue'
import { PPError } from '@/ppapi/PPError'

@Component({
  components: {
    Card,
    PageHeader,
    HelpSearchCard,
    SkeletonLine,
    EditAddressModal,
    AlertMessage,
    AddressCard,
  },
  computed: {
    ...mapState('addresses', ['addressList']),
    ...mapGetters('asyncStatus', ['getError', 'isInProgress']),
  },
})
export default class AddressesView extends Vue {
  readonly isInProgress!: (key: string) => boolean
  readonly getError!: (key: string) => PPError | null
  readonly addressList!: Address[]

  get isAwaitingAsyncUpdate() {
    return (
      this.isInProgress('addresses/delete') ||
      this.isInProgress('addresses/setCurrentShippingAddress') ||
      this.isInProgress('addresses/addOrUpdate')
    )
  }

  get isLoadingAddresses() {
    return this.isInProgress('addresses/get')
  }

  get loadingError() {
    return this.getError('addresses/get')
  }

  get hasLoadingError() {
    return !!this.loadingError
  }

  get hasAddresses() {
    return this.addressList.length > 0
  }

  get shouldShowLoadingSkeleton() {
    // Don't show the loading skeleton if we already have some addresses loaded that
    // we can show. Without this check we would show the loading skeleton while
    // "setting an address as the default" because that action re-loads all addresses.
    // Showing the skeleton at that point would be weird and distracting.
    return !!(this.isLoadingAddresses && !this.hasAddresses)
  }

  get breadcrumbs() {
    return [
      {
        text: this.$t('pages.settings.title'),
        to: { name: 'settings' },
      },
      {
        text: this.$t('pages.settings.shipping.title'),
        to: { name: 'addresses' },
      },
    ]
  }

  created(): void {
    this.$store.dispatch('addresses/get')
  }

  addAddress() {
    this.$store.dispatch('addresses/beginEditNewAddress')
  }
}
