















































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { TranslateResult } from 'vue-i18n'
import { validationMixin } from 'vuelidate'
import { required, maxLength } from 'vuelidate/lib/validators'
import { mask } from 'vue-the-mask'
import { PPError } from '@/ppapi/PPError'
import { PPAddressErrorType } from '@/ppapi/PPAddressError'
import User from '@/models/User'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import AlertMessage from '@/components/AlertMessage.vue'
import Address from '@/models/Address'
import USState from '@/util/USState'
import usStateShippable from '@/util/validators/usStateValidator'
import usZipCode from '@/util/validators/usZipValidator'
import { validationStateMixin } from '@/util/validationState'
import ZipcodeLookup from '@/models/ZipcodeLookup'
import USStateInput from '@/components/inputs/USStateInput.vue'

const MAX_LINE_LENGTH = 35

@Component({
  components: { LoadingSpinner, AlertMessage, USStateInput },
  mixins: [validationMixin, validationStateMixin],
  computed: {
    ...mapState('user', ['me']),
    ...mapState('addresses', ['zipcodeLookup']),
    ...mapGetters('addresses', ['isEditing', 'addressErrorType']),
    ...mapGetters('asyncStatus', ['getError', 'isInProgress']),
  },
  directives: { mask },
  validations: {
    address: {
      name: { required, maxLength: maxLength(MAX_LINE_LENGTH) },
      street: { required, maxLength: maxLength(MAX_LINE_LENGTH) },
      street2: { maxLength: maxLength(MAX_LINE_LENGTH) },
      city: { required, maxLength: maxLength(MAX_LINE_LENGTH) },
      state: { required, usStateShippable },
      zipcode: {
        required,
        usZipCode,
      },
    },
  },
})
export default class EditAddressModalForm extends Vue {
  readonly getError!: (key: string) => PPError | null
  readonly addressErrorType?: PPAddressErrorType
  readonly isInProgress!: (key: string) => boolean
  readonly isEditing!: boolean
  readonly zipcodeLookup!: { [s: string]: ZipcodeLookup }

  me?: User
  address: Address = this.$store.state.addresses.editor.address.clone()

  get saveButtonTitle(): TranslateResult {
    return this.isEditing ? this.$t('Update address') : this.$t('Add address')
  }

  get isCreatingNew(): boolean {
    return !this.isEditing
  }

  shouldAutoFocus(field: string) {
    const firstInvalidField = this.firstInvalidField()
    if (firstInvalidField) {
      return field === firstInvalidField
    } else {
      return field === 'street'
    }
  }

  firstInvalidField() {
    const fields = ['name', 'street', 'street2', 'zipcode', 'city', 'state']
    return fields.find(field => {
      const validation: any = this.$v.address
      return validation && validation[field] && validation[field].$invalid
    })
  }

  get invalidNameMessage(): TranslateResult | null {
    if (this.$v.address) {
      if (this.$v.address.name && this.$v.address.name.$dirty) {
        if (!this.$v.address.name.required) {
          return this.$t('_validations.required', { field: 'name' })
        }
        if (!this.$v.address.name.maxLength) {
          return this.$t('_validations.maxLength', { max: MAX_LINE_LENGTH })
        }
      }
    }
    return null
  }

  get invalidStreetMessage(): TranslateResult | null {
    if (this.$v.address) {
      if (this.$v.address.street && this.$v.address.street.$dirty) {
        if (!this.$v.address.street.required) {
          return this.$t('_validations.required', { field: 'street' })
        }
        if (!this.$v.address.street.maxLength) {
          return this.$t('_validations.maxLengthStreet', { max: MAX_LINE_LENGTH })
        }
      }
    }
    return null
  }

  get invalidStreet2Message(): TranslateResult | null {
    if (this.$v.address) {
      if (this.$v.address.street2 && this.$v.address.street2.$dirty) {
        if (!this.$v.address.street2.maxLength) {
          return this.$t('_validations.maxLength', { max: MAX_LINE_LENGTH })
        }
      }
    }
    return null
  }

  get invalidCityMessage(): TranslateResult | null {
    if (this.$v.address) {
      if (this.$v.address.city && this.$v.address.city.$dirty) {
        if (!this.$v.address.city.required) {
          return this.$t('_validations.required', { field: 'city' })
        }
        if (!this.$v.address.city.maxLength) {
          return this.$t('_validations.maxLength', { max: MAX_LINE_LENGTH })
        }
      }
    }
    return null
  }

  get invalidStateMessage(): TranslateResult | null {
    if (this.$v.address) {
      if (this.$v.address.state && this.$v.address.state.$dirty) {
        if (!this.$v.address.state.required) {
          return this.$t('_validations.required', { field: 'state' })
        }
        if (!this.$v.address.state.usStateShippable) {
          const state = USState.getStateByAbbreviation(this.address.state || '')
          if (state) {
            return this.$t('_validations.usStateShippable', { state: state.name })
          }
        }
      }
    }
    return null
  }

  get invalidZipcodeMessage(): TranslateResult | null {
    if (this.$v.address) {
      if (this.$v.address.zipcode && this.$v.address.zipcode.$dirty) {
        if (!this.$v.address.zipcode.required) {
          return this.$t('_validations.required', { field: 'ZIP code' })
        }
        if (!this.$v.address.zipcode.usZipCode) {
          return this.$t('_validations.usZipCode')
        }
      }
    }
    return null
  }

  close() {
    this.$emit('close')
  }

  created() {
    // when creating a new address prefill user's name if we have it
    if (this.isCreatingNew && this.me && this.me.fullName) {
      this.$set(this.address, 'name', this.me.fullName)
    }
  }

  @Watch('$store.state.addresses.editor.address')
  onAddressChanged(value: Address) {
    this.address = value.clone()
  }

  @Watch('address.zipcode')
  onZipcodeChange(newZipcode: string) {
    this.fetchZipInfoAndUpdateCityAndState(newZipcode)
  }

  async fetchZipInfoAndUpdateCityAndState(zipcode: string) {
    if (!usZipCode(zipcode)) return

    await this.$store.dispatch('addresses/fetchZipcodeLookup', zipcode)

    const info = this.zipcodeLookup[zipcode]
    if (this.shouldUpdateCityAndStateFromZipcode(zipcode)) {
      this.$set(this.address, 'state', info.state)
      this.$set(this.address, 'city', info.city)
    }
  }

  shouldUpdateCityAndStateFromZipcode(zipcode: string): boolean {
    if (this.address.zipcode !== zipcode) {
      // the user changed the zipcode so this info is out of date
      return false
    }

    const userTouchedCityOrState = this.$v.address!.city!.$dirty || this.$v.address!.state!.$dirty
    const hasCityOrStateValues = this.address.city || this.address.state

    // the user manually modified city or state so we won't overwrite them
    // we will allow overwriting if the user just deleted the values
    return !(userTouchedCityOrState && hasCityOrStateValues)
  }

  async validate(): Promise<boolean> {
    this.$v.$touch()
    if (this.$v.$invalid) {
      return false
    }
    this.$emit('submitAddress', this.address)
    return true
  }
}
