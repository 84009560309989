














































import { Component, Vue } from 'vue-property-decorator'
import Address from '@/models/Address'
import AddressElement from '@/components/Address.vue'
import BackLink from '@/components/BackLink.vue'
import Card from '@/components/Card.vue'

@Component({
  components: { AddressElement, BackLink, Card },
})
export default class EditAddressModalConfirmSuggestedAddress extends Vue {
  readonly suggested: Address = this.$store.getters['addresses/suggestedAddress']
  readonly original: Address = this.$store.state.addresses.editor.address
  selectedAddress: Address | null = null

  created() {
    this.selectedAddress = this.suggested
  }

  get addressOptions() {
    return [this.suggested, this.original]
  }

  keyForAddress(address: Address) {
    switch (address) {
      case this.suggested:
        return 'suggested'
      case this.original:
        return 'original'
      default:
        throw new Error('Unexpected address')
    }
  }

  isSelectedAddress(address: Address) {
    return this.selectedAddress === address
  }

  headerForAddress(address: Address) {
    switch (address) {
      case this.suggested:
        return this.$t('Suggested')
      case this.original:
        return this.$t('What you entered')
      default:
        return ''
    }
  }

  clickedAddress(address: Address) {
    this.selectedAddress = address
  }

  confirmAddress() {
    const shouldForceSave = this.selectedAddress === this.original
    this.$emit('confirmAddress', this.selectedAddress, shouldForceSave)
  }

  back() {
    this.$emit('back')
  }

  close() {
    this.$emit('close')
  }
}
