







































import { Component, Vue, Prop } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { TranslateResult } from 'vue-i18n'
import Modal from '@/components/Modal.vue'
import EditAddressModalForm from '@/components/settings/addresses/EditAddressModalForm.vue'
import EditAddressModalConfirmSuggestedAddress from '@/components/settings/addresses/EditAddressModalConfirmSuggestedAddress.vue'
import EditAddressModalConfirmAddress from '@/components/settings/addresses/EditAddressModalConfirmAddress.vue'
import Address from '@/models/Address'
import Page from '@/components/settings/addresses/EditAddressModalScreen'
import { PPAddressErrorType } from '@/ppapi/PPAddressError'

@Component({
  components: {
    Modal,
    EditAddressModalForm,
    EditAddressModalConfirmAddress,
    EditAddressModalConfirmSuggestedAddress,
  },
  computed: {
    ...mapGetters('asyncStatus', ['hasSucceeded', 'isInProgress']),
    ...mapGetters('addresses', ['isEditing', 'addressErrorType']),
  },
})
export default class EditAddressModal extends Vue {
  readonly hasSucceeded!: (key: string) => boolean
  readonly hasFailed!: (key: string) => boolean
  readonly isEditing!: boolean
  readonly addressErrorType?: PPAddressErrorType

  $refs!: {
    addressForm: EditAddressModalForm
  }

  currentPage: Page = Page.form

  @Prop({ type: String, required: true })
  readonly modalId!: string

  get isPageForm() {
    return this.currentPage === Page.form
  }

  get isPageSuggestion() {
    return this.currentPage === Page.suggestion
  }

  get isPageConfirm() {
    return this.currentPage === Page.confirm
  }

  get visible(): boolean {
    return this.$store.state.addresses.editor.show
  }

  get title(): TranslateResult {
    switch (this.currentPage) {
      case Page.form:
        return this.isEditing ? this.$t('Edit address') : this.$t('Add new address')
      default:
        // all other steps have no title
        return ''
    }
  }

  get successToastText(): TranslateResult {
    return this.isEditing ? this.$t('addresses.addressUpdated') : this.$t('addresses.addressAdded')
  }

  submitAddress(address: Address) {
    this.$store.commit('addresses/setEditorAddress', address)
    this.submit(address, false)
  }

  async confirmAddress(address: Address, force: boolean) {
    this.$store.dispatch('addresses/confirmAddress', address)
    // since we are updating the address and letting vuelidate validate the form, we have to wait
    // for Vue's event loop to complete so the form is up to date.
    await this.$nextTick()
    if (force) {
      this.submit(address, force)
    } else {
      // if we are not force saving this address (e.g. when we use the USPS suggestedAddress)
      // we have the form validate and submit it. This catches any edge cases where the USPS
      // suggests an address that doesn't pass client side validation (e.g. state undeliverable,
      // street too long) and displays an appropriate error message.
      this.validateInFormAndSubmitIfValid()
    }
  }

  async validateInFormAndSubmitIfValid() {
    const passed = await this.$refs.addressForm.validate()
    if (!passed) {
      this.reset()
    }
  }

  async submit(address: Address, force: boolean) {
    await this.$store.dispatch('addresses/addOrUpdate', { address, force })

    if (this.hasSucceeded('addresses/addOrUpdate')) {
      this.$bvToast.toast(this.successToastText as string, {
        title: this.$t('Address saved') as string,
        variant: 'success',
      })
      this.close()
    } else if (this.addressErrorType === PPAddressErrorType.stateRequiresId) {
      this.$store.dispatch('user/setUserIdentificationModal', {
        visible: true,
        onComplete: this.$refs.addressForm.validate,
      })
    } else if (this.addressErrorType === PPAddressErrorType.addressSuggested) {
      this.currentPage = Page.suggestion
    } else if (this.addressErrorType) {
      this.currentPage = Page.confirm
    }
  }

  reset() {
    this.currentPage = Page.form
  }

  close() {
    this.$store.commit('addresses/showEditor', false)
  }
}
