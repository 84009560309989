import { render, staticRenderFns } from "./EditAddressModalConfirmSuggestedAddress.vue?vue&type=template&id=7d9ad4b7&"
import script from "./EditAddressModalConfirmSuggestedAddress.vue?vue&type=script&lang=ts&"
export * from "./EditAddressModalConfirmSuggestedAddress.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { BButton } from 'bootstrap-vue'
import { BCol } from 'bootstrap-vue'
import { BListGroup } from 'bootstrap-vue'
import { BListGroupItem } from 'bootstrap-vue'
import { BRow } from 'bootstrap-vue'
import IconCheckEmpty from '@/assets/icons/icon-check-empty.svg'
import IconCheckGreen from '@/assets/icons/icon-check-green.svg'
installComponents(component, {BButton,BCol,BListGroup,BListGroupItem,BRow,IconCheckEmpty,IconCheckGreen})
